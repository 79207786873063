import React from "react"
import loadable from "@loadable/component"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="Ida"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01 // bots-prod-01
      botId="ddy1j2xoksis7ryy"
      domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={true}
      hasGDPR={false}
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Deine Frage...",
      }}
      styleCustomizations={{
        "--button-blue": "#394d75",
        "--button-blue-light": "#4C679C",
      }}
      // gdprButtonText="Zustimmen"
      // gdprText={
      //   <>
      //     Die{" "}
      //     <a
      //       href="https://www.gegenbauer.de/datenschutzerklaerung/"
      //       target="_blank"
      //     >
      //       Datenschutzerklärung
      //     </a>{" "}
      //     habe ich zur Kenntnis genommen und ich erkläre mich damit
      //     einverstanden, dass meine Daten zum Zwecke der Bearbeitung meiner
      //     Anfrage verarbeitet werden. Ihre Einwilligung können Sie jederzeit
      //     widerrufen. Durch den Widerruf der Einwilligung wird die
      //     Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
      //     erfolgten Verarbeitung nicht berührt.
      //   </>
      // }
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
        botAvatarBackgroundColor: "#fff",
        suggestedActionLayout: "stacked",
      }}
      // css="https://static.convaise.com/webchat/v4/convaise-assistant-min_v4-1.css"
    />
  )
}

export default Chat
